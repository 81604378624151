@import "./../../app/variables.scss";
@import '~app/mixins';

.container {
  padding: 0;
  margin: 5px 0;
  .section {
    position: relative;
    width: 65px;
    height: 5px;
    background-color: #BCC1C7;
    border-radius: 6px;
    border: 0;
    overflow: hidden;
    .progressBar {
      height: 100%;
      // border-radius: 6px;
      background-color: $color-teal;
      transition: width 0.3s ease-in-out;
    }
    .verticalLine {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #EFF2F6;
      z-index: 1;
    }
  }
  .bigSection {
    position: relative;
    width: 250px;
    height: 10px;
    background-color: #BCC1C7;
    border-radius: 6px;
    border: 0;
    overflow: hidden;
    .progressBar {
      height: 100%;
      // border-radius: 6px;
      background-color: $color-teal;
      transition: width 0.3s ease-in-out;
    }
    .verticalLine {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #EFF2F6;
      z-index: 1;
    }
  }
  .mediumSection {
    position: relative;
    width: 130px;
    height: 10px;
    background-color: #BCC1C7;
    border-radius: 6px;
    border: 0;
    overflow: hidden;
    .progressBar {
      height: 100%;
      // border-radius: 6px;
      background-color: $color-teal;
      transition: width 0.3s ease-in-out;
    }
    .verticalLine {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #EFF2F6;
      z-index: 1;
    }
  }
}
